export default [
    {
        key: 'uid',
        label: 'UID',
        sortable: false,
    },
    {
        key: 'refid',
        label: 'Ref Id',
        sortable: false,
    },
    {
        key: 'paymentType',
        label: 'Type',
        sortable: false,
    },
    {
        key: 'amount',
        label: 'Amount',
        sortable: false,
    },
];
