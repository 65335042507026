<template>
  <div v-if="transaction" class="worker-details">
    <SidebarHeader :handle-sidebar="handleSidebar" :is-back-button-active="true"
                   :is-transaction-update="true"
                   :title="$t('admin.transactions.update.title')"/>
    <hr/>
    <div class="worker-profile basic-padding" style="margin-top: 15%;">
      <SideTitleSidebarComponent :title="$t('admin.transactions.update.adjust.title')"/>
      <b-row>
        <b-col cols="8" style="display: flex;align-items: self-end;">
          <h6>{{ $t('admin.transactions.detail.status') }}</h6>
        </b-col>
        <b-col cols="4">
          <b-dropdown v-if="listOfStatuses.length > 0" size="sm" split variant="transparent">
            <template #button-content>
              <div :class="computedSelectedStatus.className"><p>{{ $t(computedSelectedStatus.name) }}</p>
              </div>
            </template>
            <b-dropdown-item-button v-for="status in listOfStatuses"
                                    :key="`_${status.symbol}_${Math.random()}`"
                                    style="margin: 0; padding: 0">
              <template>
                <div :class="status.className" @click="setStatus(status.symbol)">
                  <p>{{ $t(status.name) }}</p>
                </div>
              </template>
            </b-dropdown-item-button>
          </b-dropdown>
        </b-col>
      </b-row>
      <BlankLine :number-of-rows="1"/>
      <hr/>
      <BlankLine :number-of-rows="1"/>
      <SidebarEntry :title="$t('admin.transactions.detail.reason')" value=""/>
      <SidebarTextArea
          :max="maxCharMotivation"
          :min="minCharMotivation"
          :set-motivation="setMotivationText"
      />
      <BlankLine :number-of-rows="1"/>
      <SidebarEntry v-if="isRefIdModifiable" :title="$t('admin.transactions.detail.refid')"
                    :value="transaction.refid"/>
      <SidebarRadio v-if="isRefIdModifiable" :is-disabled="!isRefIdModifiable" :options="radioOptionsRefId"
                    :set-selection="setRefIdChoice"
                    name="refId"/>
      <b-row v-if="doesRefIdNeedUpdate">
        <b-col>
          <input :value="`${transaction.refid}_${this.refIdChoice.toString().toLowerCase()}`"
                 class="modify-user-input"
                 disabled
          />
        </b-col>
      </b-row>
      <BlankLine :number-of-rows="1"/>
      <SidebarEntry v-if="isTxIdModifiable" :title="$t('admin.transactions.detail.id')"
                    :value="transaction.transactionIdExt"/>
      <SidebarRadio v-if="isTxIdModifiable"
                    :is-disabled="!isTxIdModifiable"
                    :options="radioOptionsTxId"
                    :set-selection="setIdChoice"
                    name="txId"/>
      <b-row v-if="doesTxIdNeedUpdate">
        <b-col><input :value="`${transaction.transactionIdExt}_${this.txIdChoice.toString().toLowerCase()}`"
                      class="modify-user-input"
                      disabled/></b-col>
      </b-row>
      <BlankLine :number-of-rows="1"/>
      <SidebarEntry v-if="isExecutionDateModifiable"
                    :title="$t('admin.transactions.detail.date')"
                    :value="transaction.updateDate"/>
      <SidebarRadio v-if="isExecutionDateModifiable"
                    :is-disabled="!isExecutionDateModifiable" :options="radioOptionsDate"
                    :set-selection="setDateChoice"
                    name="date"/>
      <b-row v-if="doesDateNeedUpdate">
        <b-col>
          <SidebarDateTimePicker :set-new-date="setNewDate"/>
        </b-col>
      </b-row>
      <BlankLine :number-of-rows="1"/>
      <SidebarEntry :title="$t('admin.transactions.detail.payment-data.title')" value=" "/>
      <SidebarCheckbox :default-value="isIbanVerificationRequired"
                       :event-reset="this.RESET_VERIFY_IBAN"
                       :event-select="this.SELECT_VERIFY_IBAN"
                       :is-disabled="false"
                       :name="$t('admin.transactions.detail.payment-data.verify')"
                       :set-selection="setsIsIbanVerificationRequired"
                       :text="$t('admin.transactions.detail.payment-data.verify')"/>
      <BlankLine :number-of-rows="1"/>
      <ButtonContainer>
        <template v-slot:mainButton>
          <MainButton :button-text="$t('admin.common.save')"
                      :is-active="isFormEnabled"
                      :on-click="updateTransaction"/>
        </template>
      </ButtonContainer>
    </div>
  </div>
</template>

<script>
import SidebarHeader from '@/components/Layout/Header/SidebarHeader';
import SideTitleSidebarComponent from '@/views/sidebar/basic/SideTitleSidebarComponent';
import SidebarEntry from '@/views/sidebar/basic/SidebarEntry';
import SidebarTextArea from '@/views/sidebar/basic/SidebarTextArea';
import FormatUtils from '@/utils/FormatUtils';
import SidebarRadio from '@/views/sidebar/basic/SidebarRadio';
import transactionUpdateOptions, {correct, retry, wrong} from '@/utils/TransactionUpdate';
import ButtonContainer from '@/components/Buttons/ButtonContainer';
import MainButton from '@/components/Buttons/MainButton';
import SidebarDateTimePicker from '@/views/sidebar/basic/SidebarDateTimePicker';
import PaymentType from '@/utils/PaymentType';
import ToasterUtils from '@/utils/ToasterUtils';
import FilterConstant from '@/components/Filter/FilterConstant';
import SidebarCheckbox from '@/views/sidebar/basic/SidebarCheckbox.vue';
import PaymentStatus, {getPaymentStatus} from '@/utils/PaymentStatus';
import BlankLine from '@/components/Blank/BlankLine.vue';

const SUCCESS_STATUS = 200;
export default {
    name: 'TransactionUpdate',
    mixins: [FormatUtils, ToasterUtils, FilterConstant],
    components: {
        BlankLine,
        SidebarCheckbox,
        SidebarDateTimePicker,
        ButtonContainer,
        SidebarRadio,
        SidebarTextArea,
        SidebarEntry,
        SideTitleSidebarComponent,
        SidebarHeader,
        MainButton
    },
    props: {
        transaction: {
            type: Object,
            required: true,
            default: () => {
            }
        },
        paymentType: {
            type: String,
            required: true,
            default: () => PaymentType.WALC.symbol,
        },
        tableRef: {
            type: Object,
            required: false,
            default: () => {
            }
        }
    },
    data() {
        return {
            maxCharMotivation: 400,
            minCharMotivation: 10,
            motivationText: '',
            refIdChoice: '',
            txIdChoice: '',
            dateChoice: '',
            radioOptionsRefId: transactionUpdateOptions.twoChoices,
            radioOptionsTxId: transactionUpdateOptions.twoChoices,
            radioOptionsDate: transactionUpdateOptions.twoChoices,
            newDate: '',
            isIbanVerificationRequired: false,
            selectedStatus: this.transaction.status,
            statuses: [
                PaymentStatus.TOBE_EXECUTED.symbol,
                PaymentStatus.EXECUTED.symbol
            ],
            generalPaymentType: PaymentType.UNKNOWN.symbol
        };
    },
    mounted() {
        if (this.isTxIdModifiable) {
            this.txIdChoice = transactionUpdateOptions.wrong.item;
        }
        this.$root.$emit(this.SELECT_TRANSACTION_STATUS, transactionUpdateOptions.wrong.item);
        if (this.transaction.status === PaymentStatus.TOBE_EXECUTED.symbol) {
            this.radioOptionsTxId = transactionUpdateOptions.threeChoices;
        }
        this.generalPaymentType = this.$store.getters.getWalletTypeByCompanyId(this.transaction.companyId);
    },
    watch: {
        selectedStatus: {
            handler(newVal) {
                if (newVal === PaymentStatus.TOBE_EXECUTED.symbol) {
                    this.radioOptionsTxId = transactionUpdateOptions.threeChoices;
                    this.radioOptionsRefId = transactionUpdateOptions.threeChoices;
                }
                this.txIdChoice = '';
                this.$root.$emit(this.RESET_TRANSACTION_STATUS);
            }
        }
    },
    computed: {
        computedSelectedStatus() {
            return this.getPaymentStatus(this.selectedStatus, this.transaction.approvalStatus, this.transaction.isCompanyTwoLevelApproval);
        },
        listOfStatuses() {
            const listOfDefaultStatuses = [this.transaction.status];
            this.statuses.forEach(status => listOfDefaultStatuses.push(status));
            return listOfDefaultStatuses
                .map(status => this.getPaymentStatus(status, this.transaction.approvalStatus, this.transaction.isCompanyTwoLevelApproval));
        },
        isReasonFilled() {
            return this.motivationText !== '' &&
          this.motivationText.length >= this.minCharMotivation &&
          this.motivationText.length <= this.maxCharMotivation;
        },
        isRefIdModifiable() {
            const isStatusRefIdModifiable =
          this.transaction.status === PaymentStatus.REFUSED.symbol ||
          this.transaction.status === PaymentStatus.REJECTED.symbol;
            return this.isTypePis &&
          isStatusRefIdModifiable;
        },
        isTxIdModifiable() {
            const isStatusRefIdModifiable =
          this.transaction.status === PaymentStatus.REFUSED.symbol ||
          this.transaction.status === PaymentStatus.REJECTED.symbol;
            return (this.isWalletType || this.isTypePis) &&
          isStatusRefIdModifiable;
        },
        isExecutionDateModifiable() {
            return this.computedSelectedStatus.symbol === PaymentStatus.EXECUTED.symbol;
        },
        isRefIdSelected() {
            return this.refIdChoice !== '';
        },
        doesRefIdNeedUpdate() {
            return this.refIdChoice === wrong.item || this.refIdChoice === retry.item;
        },
        isTxIdSelected() {
            return this.txIdChoice !== '';
        },
        doesTxIdNeedUpdate() {
            return this.txIdChoice === wrong.item || this.txIdChoice === retry.item;
        },
        isDateSelected() {
            return this.dateChoice !== '';
        },
        doesDateNeedUpdate() {
            return this.dateChoice === wrong.item;
        },
        isRefIdValid() {
            if (this.isRefIdModifiable) {
                return this.isRefIdSelected;
            } else {
                return true;
            }
        },
        isTxIdValid() {
            if (this.isTxIdModifiable) {
                return this.isTxIdSelected;
            } else {
                return true;
            }
        },
        isDateValid() {
            if (this.isExecutionDateModifiable) {
                if (this.doesDateNeedUpdate) {
                    return this.isDateSelected && !this.newDate.includes('null');
                } else {
                    return this.isDateSelected;
                }
            }
            return true;
        },
        isFormEnabled () {
            return this.isReasonFilled &&
          this.isRefIdValid &&
          this.isTxIdValid &&
          this.isDateValid;
        },
        isTypePis() {
            return this.generalPaymentType === PaymentType.PIS.symbol;
        },
        isTypeWal() {
            return this.generalPaymentType === PaymentType.WAL.symbol;
        },
        isTypeWalc() {
            return this.generalPaymentType === PaymentType.WALC.symbol;
        },
        isWalletType() {
            return this.isTypeWal || this.isTypeWalc;
        }
    },
    methods: {
        getPaymentStatus,
        handleSidebar() {
            this.$root.$emit('bv::toggle::collapse', 'update-tx');
        },
        setMotivationText(motivation) {
            this.motivationText = motivation;
        },
        setRefIdChoice(choice) {
            this.refIdChoice = choice;
        },
        setIdChoice(choice) {
            this.txIdChoice = choice;
        },
        setDateChoice(choice) {
            this.dateChoice = choice;
        },
        setNewDate(newDate) {
            this.newDate = newDate;
        },
        setsIsIbanVerificationRequired(isIbanVerificationRequired) {
            this.isIbanVerificationRequired = isIbanVerificationRequired;
        },
        updateTransaction() {
            const requestBody = {
                id: this.transaction.id,
                reason: this.motivationText
            };
            if (this.computedSelectedStatus.symbol !== this.transaction.status) {
                requestBody.newStatus = this.computedSelectedStatus.symbol;
            }
            if (this.refIdChoice !== correct.item && this.refIdChoice !== '') {
                requestBody.refIdChangeType = this.refIdChoice;
            }
            if (this.txIdChoice !== correct.item && this.txIdChoice !== '') {
                requestBody.transactionIdExtChangeType = this.txIdChoice;
            }
            if (this.dateChoice !== correct.item && this.dateChoice !== '') {
                requestBody.newExecutionDate = this.newDate;
            }
            if (this.isIbanVerificationRequired) {
                requestBody.verifyIban = this.isIbanVerificationRequired;
            }
            this.$api.updatePayment(requestBody, this.generalPaymentType)
                .then(response => {
                    this.handleSidebar();
                    if (this.tableRef) {
                        this.tableRef.refresh();
                    }
                    this.makeToast(response.status === SUCCESS_STATUS, response.data.message);
                })
                .catch(error => {
                    this.makeToast(false, `Error: ${error}, This functionality works only with payouts. Check what you are trying to update.`);
                });
        },
        setStatus(status) {
            this.selectedStatus = status;
            this.resetChoices();
        },
        resetChoices() {
            this.setRefIdChoice('');
            this.setIdChoice('');
            this.setDateChoice('');
            this.setNewDate('');
            this.$root.$emit(this.RESET_TRANSACTION_STATUS);
        }
    }
};
</script>
