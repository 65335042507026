<template>
  <div :class="className">
    <img v-if="image" :alt="title" :src=image />
    <p v-if="!isResponsive">{{ title }}</p>
    <p v-if="isResponsive && showText"
       :class="ellipseCustomClass">
      {{ title }}
    </p>
  </div>
</template>

<script>
import Resizer from '@/utils/Resizer';

export default {
    name: 'TransactionStatus',
    mixins: [Resizer],
    props: {
        title: {
            type: String,
            required: true,
            default: () => 'TransactionStatus',
        },
        className: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: false,

        },
        isResponsive: {
            type: Boolean,
            default: () => false,
        }
    },
    data() {
        return {
            w: window.innerWidth,
        };
    },
    computed: {
        showText() {
            return this.w >= 1100;
        },
        ellipseCustomClass() {
            switch (true) {
            case this.w <= this.microWindowWidth:
                return this.superEllipsis;
            case this.w <= this.littleWindowWidth:
                return this.superEllipsis;
            case this.w <= this.mediumWindowWidth:
                return this.mediumWindowWidth;
            case this.w <= this.bigWindowWidth:
                return this.strongEllipsis;
            default:
                return '';
            }
        }
    },
    mounted() {
        window.addEventListener(this.resizeEvent, this.onWindowResize);
    },
    beforeDestroy() {
        window.removeEventListener(this.resizeEvent, this.onWindowResize);
    },
    methods: {
        onWindowResize() {
            this.w = window.innerWidth;
        }
    }
};
</script>
