export default [
    {
        key: 'selection',
        label: '[]',
        sortable: false,
    },
    // {
    //     key: 'uid',
    //     label: 'UID',
    //     sortable: false,
    // },
    {
        key: 'refid',
        label: 'Ref Id',
        sortable: true,
    },
    {
        key: 'companyId',
        label: 'Company Name',
        sortable: true,
    },
    {
        key: 'counterpartyName',
        label: 'Name',
        sortable: false,
    },
    // {
    //     key: 'counterpartyIban',
    //     label: 'IBAN',
    //     sortable: false,
    // },
    {
        key: 'creationDate',
        label: 'Creation Date',
        sortable: true,
    },
    // {
    //     key: 'executionDate',
    //     label: 'Exec Date',
    //     sortable: true,
    // },
    {
        key: 'paymentType',
        label: 'Type',
        sortable: false,
    },
    {
        key: 'amount',
        label: 'Amount',
        sortable: false,
    },
    {
        key: 'status',
        label: 'Status',
        sortable: false,
    },
    {
        key: 'action',
        label: 'Action',
        sortable: false,
    },
    {
        key: 'reasonNote',
        label: 'reasonNote',
        sortable: false,
    },
];
